// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$primary-palette: (
  50: #def3ed,
  100: #afe1d1,
  200: #7bceb3,
  300: #42ba96,
  400: #00aa81,
  500: #009a6f,
  600: #008d63,
  700: #007d54,
  800: #006d47,
  900: #00502d,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
  ),
);

$accent-palette: (
  50: #e6f1f8,
  100: #c2dcee,
  200: #9fc6e3,
  300: #80b0d7,
  400: #6da0cf,
  500: #5e91c7,
  600: #5584ba,
  700: #4b73a8,
  800: #436296,
  900: #344675,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$black-dashboard-angular-primary: mat.define-palette($primary-palette);
$black-dashboard-angular-accent: mat.define-palette($accent-palette);

// The warn palette is optional (defaults to red).
$black-dashboard-angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$black-dashboard-angular-theme: mat.define-light-theme(
  (
    color: (
      primary: $black-dashboard-angular-primary,
      accent: $black-dashboard-angular-accent,
      warn: $black-dashboard-angular-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($black-dashboard-angular-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button {
  outline: none !important;
}

button:active {
  outline: none !important;
}

.colorSettingsTable {
  color: #777;
}

.inputWithClean {
  position: relative;
}

.cleanInput {
  cursor: pointer;
  top: 30%;
  position: absolute;
  right: 10px;
}

.containerRelative {
  position: relative;
}

.tableContainer {
  position: relative;
  min-height: 200px;
  max-height: 700px;
  overflow: auto;
}

table {
  width: 100%;
}

.loadingShade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

th,
td {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pointerBox {
  cursor: pointer;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}
